.sgta {
  display: flex;
  justify-content: center;

  &.block-content-wrapper {
    .block-content-inner {
      flex: 0 1 400px;
      margin-right: 20px;
      .block-text {
        margin-bottom: 20px;
      }
    }

    .block-content-image-wrapper {
      flex: 0 1 410px;
      img {
        max-width: 410px;
      }
    }
  }

  .button {
    float: left;
    margin: 10px;
    letter-spacing: 1px;
    &.first {
      margin-left: 0;
    }
    &.last {
      margin-right: 0;
    }
  }

  .button--white a {
    background-color: transparent;
  }

  .button.button-more {
    float: right;
  }

  &.buttons {
    .button {
      float: right;
      margin: 10px;
      &.first {
        margin-left: 0;
      }
      &.last {
        margin-right: 0;
      }
    }
  }
}

.sgta.dashboard-wrapper {
  margin-bottom: 20px;
  margin-top: 20px;

  h4.title {

  }
}

.sgta.manage-ops {
  display: inline-block;
  div.ops-trigger {
    display: block;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .item-list {
    padding-top: 10px;
    position: absolute;

    .manage-ops-links {
      margin: 0;
      display: block;
      li {
        display: block;
        margin: 0px;
        padding: 0px;
        background: #e3e3e3;
        border: solid 1px #c3c3c3;
        border-bottom: none 0 transparent;
        a {
          padding: 5px 15px;
          text-decoration: none;
          display: block;
          &:hover {
            text-decoration: underline;
          }
        }
        &.last {
          border-bottom: solid 1px #c3c3c3;
        }
      }
    }
  }
  &.open {
    position: relative;
    .item-list {
      position: absolute;
      left: 50%;
      .manage-ops-links {
        position: relative;
        left: -50%;
      }
    }
  }
}

#block-views-sgta-courses-calendar-calendar {
  display: none;
}

#block-sgta-sgta-academy-prospectus {
  .block-bg-wrapper {
    position: relative;
    img {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: auto;
      max-height: 190px;
      z-index: 5;
    }

    .button {
      padding: 60px 0;
      text-align: center;
      position: relative;
      z-index: 8;
      a {
        display: inline-block;
        margin: auto;
      }
    }
  }
}